<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">

      <h5 class="text-center mb-5">Майнор ОП</h5>

<!--      <div v-if="minorFormationEducationProgram_form.minorFormationEducationProgram.length > 0">-->



<!--        -->

<!--      </div>-->

      <div>
        <section class="mt-4 mb-4">

          <ul v-if="minorFormationEducationProgram_form.minorFormationEducationProgram.length > 0">
            Привязанные дисциплины:
            <li v-for="(item, index) in minorFormationEducationProgram_form.minorFormationEducationProgram"
                :key="index" class="mt-4">
              {{ item.discipline_name }} - Кредит: {{ item.credit }} - Семестр: {{ item.semester }}

              <Button v-if="minorFormationEducationProgram_form.educationProgramMinors.length == 0" icon="pi pi-trash" class="p-button-rounded p-button-danger mx-3"
                      @click="deleteMinorFormation(item.id)"/>
            </li>
          </ul>
          <strong>Сумма кредитов: {{ allCreditSum }}</strong>
        </section>



        <!-- filter -->
        <section class="mt-4">
          <!--        <div class="row mt-4">-->
          <!--          <label class="col-md-3 col-form-label">Год поступления</label>-->
          <!--          <div class="col-md-9">-->
          <!--            <select class="form-control form-select" v-model="admissionYear">-->
          <!--              <option :value="0" hidden>Выберите</option>-->
          <!--              <option v-for="(item, index) in admissionYears" :key="index" :value="item">-->
          <!--                {{item}}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--        </div>-->


          <!--        <div class="row mt-4">-->
          <!--          <label class="col-md-3 col-form-label">Уровень обучения</label>-->
          <!--          <div class="col-md-9">-->
          <!--            <select class="form-control form-select" v-model="studyLevelId">-->
          <!--              <option :value="0" hidden>Выберите</option>-->
          <!--              <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index" :value="item.id">-->
          <!--                {{item.name}}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--        </div>-->

          <!--        <div class="row mt-4">-->
          <!--          <label class="col-md-3 col-form-label">Язык обучения</label>-->
          <!--          <div class="col-md-9">-->
          <!--            <select class="form-control form-select" v-model="studyLanguageId">-->
          <!--              <option :value="0" hidden>Выберите</option>-->
          <!--              <option v-for="(item, index) in studyLanguages" :key="index" :value="item.id">-->
          <!--                {{item.native_name}}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--        </div>-->

          <div class="row mt-4" v-if="minorFormationEducationProgram_form.educationProgramMinors.length == 0">
            <label class="col-md-3 col-form-label">Образовательная программа</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="educationProgramId">
                <option :value="0" hidden>Выберите</option>
                <option v-for="(item, index) in minorFormationEducationProgram_form.educationPrograms" :key="index" :value="item.id">
                  {{item.education_speciality_code}} {{item.education_speciality_name}} - {{item.admission_year}}
                </option>
              </select>
            </div>
          </div>




          <div v-if="educationProgramId" class="my-4 text-center">
            <Button icon="pi pi-eye" label="Показать дисциплины" class="p-button-rounded" :loading="loadingDisciplines"
                    @click="getDisciplines"/>
          </div>
        </section>
        <!-- end filter -->


        <!--      <section>-->
        <!--        <div class="mt-4 mb-4" v-if="educationPrograms.find(i=>i.id == educationProgramId).minor_formation_education_program.length > 0">-->
        <!--          -->
        <!--          -->
        <!--        </div>-->
        <!--        -->
        <!--      </section>-->


        <!-- disciplines -->
        <section>
          <div class="mt-5 mb-5" v-if="isShowData&&minorFormationEducationProgram_form.disciplines.length">


            <div>


              <DataTable :value="minorFormationEducationProgram_form.disciplines" showGridlines stripedRows responsiveLayout="scroll">

                <Column header="Название дисциплины" field="discipline_name"></Column>
                <Column header="Семестр" field="semester"></Column>
                <Column header="Кредит" field="credit"></Column>

                <Column>
                  <!--                <template #header>-->
                  <!--                  Выбрать все <input class="ms-2 form-check-input" type="checkbox" @input="checkAllDisciplines">-->
                  <!--                </template>-->
                  <template #body="{data}">
                    <!--                {{data.minor_formation_education_program.find(i=>i.education_discipline_id == data.discipline_id)}}-->
                    <!--                  <div v-if="data.minor_formation_education_program?.find(i=>i.education_discipline_id == data.discipline_id)">-->
                    <!--                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"-->
                    <!--                            @click="deleteMinorFormation(data.minor_formation_education_program?.find(i=>i.education_discipline_id == data.discipline_id).id)"/>-->
                    <!--                  </div>-->
                    <!--                  <div v-else>-->
                    <input class="form-check-input" type="checkbox" :value="data.formation_education_program_id" v-model="checkedDisciplines">
                    <!--                  </div>-->

                  </template>
                </Column>


              </DataTable>


              <div class="text-center mt-4">
                <Button icon="pi pi-save" label="Сохранить"
                        :loading="loadingMinor"
                        @click="saveMinor"
                />
              </div>

            </div>





          </div>



        </section>
        <!-- end disciplines -->




      </div>






    </div>
  </div>
</template>


<script>

import {mapActions, mapMutations, mapState} from "vuex"
import minorFormationEducationProgram from "@/store/modules/education-programme/minorFormationEducationProgram";


export default {
  name: "MinorFormationEducationProgram",
  data() {
    return {
      loading: true,
      urlMinorEducationProgramId: this.$route.query.minor_education_program_id || 0,

      admissionYears: [2018, 2019, 2020, 2021, 2022, 2023],
      admissionYear: 0,
      studyLevelId: 0,
      studyLanguageId: 0,

      educationProgramId: 0,
      formationId: 0,


      isShowData: false,

      loadingDisciplines: false,
      loadingMinor: false,

      checkedDisciplines: [],

    }
  },
  computed: {
    //...mapState('adviserChoose', ['adviserChoose_form']),
    ...mapState('departmentPractice', ['pps', 'studyLanguages']),

    ...mapState('minorFormationEducationProgram', ['minorFormationEducationProgram_form']),
    allCreditSum() {
      let allCreditSum =  this.minorFormationEducationProgram_form.minorFormationEducationProgram?.reduce((sum, item) => +item.credit + sum, 0)
      return allCreditSum
    },
  },
  watch: {
    // studyLevelId() {
    //   this.watchLevelAndLang()
    // },
    // studyLanguageId() {
    //   this.watchLevelAndLang()
    // },
    educationProgramId() {
      this.CLEAR_DISCIPLINES()
    }
    // async educationProgramId() {
    //   this.clearDisciplines()
    //   this.changeShowData(false)
    //   this.educationProgramId = 0
    //
    // },
    // formationId() {
    //   this.clearPpsAndStudents()
    //   this.changeShowData(false)
    // }
  },
  methods: {
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
    ...mapActions('departmentPractice', ['GET_STUDY_LANGUAGES',]),

    ...mapActions('minorFormationEducationProgram', ['GET_VOK_DISCIPLINES', 'GET_MINOR_FORMATION_EDUCATION_PROGRAM',
      'POST_MINOR_FORMATION_EDUCATION_PROGRAM', 'GET_EDUCATION_PROGRAMS_FOR_MINOR', 'DELETE_MINOR_FORMATION', 'GET_EDUCATION_PROGRAM_MINORS']),
    ...mapMutations('minorFormationEducationProgram', ['CLEAR_DISCIPLINES']),

    async deleteMinorFormation(id) {

      await this.DELETE_MINOR_FORMATION(id)
      this.$message({title: 'Успешно удалено'})
      //if (res) {
      await this.GET_MINOR_FORMATION_EDUCATION_PROGRAM(this.urlMinorEducationProgramId)

        //this.studyLevelId = 0
        //this.studyLanguageId = 0
        //this.educationProgramId = 0
        //this.isShowData = false
      //}
    },

    async saveMinor() {
      this.loadingMinor = true
      // const form = this.checkedStudents.map(student_id => ({
      //   student_id,
      //   pps_id: this.ppsId,
      //   formation_id: this.formationId
      // }))

      const form = {
        minor_education_program_id: this.urlMinorEducationProgramId,
        formation_ids: this.checkedDisciplines
      }

      const res = await this.POST_MINOR_FORMATION_EDUCATION_PROGRAM(form)

      if (res) {
        await this.getDisciplines()
        await this.GET_MINOR_FORMATION_EDUCATION_PROGRAM(this.urlMinorEducationProgramId)
        this.$message({title: 'Успешно сохранено'})
        //this.studyLevelId = 0
        //this.studyLanguageId = 0
        this.educationProgramId = 0
        this.isShowData = false
        this.CLEAR_DISCIPLINES()
      }

      this.loadingMinor = false
    },

    checkAllDisciplines(e) {
      this.checkedDisciplines = e.target.checked ? this.minorFormationEducationProgram_form.disciplines.map(i => i.id) : []
    },

    changeShowData(isVisible) {
      this.isShowData = isVisible
    },
    // async watchLevelAndLang() {
    //   this.changeShowData(false)
    //
    //   this.educationProgramId = 0
    //   //this.formationId = 0
    //   //this.clearDisciplines()
    //
    //   if (this.studyLevelId && this.studyLanguageId) {
    //     await this.GET_EDUCATION_PROGRAMS_FOR_MINOR({
    //       studyLevel: this.studyLevelId,
    //       studyLanguage: this.studyLanguageId
    //     })
    //   }
    // },
    async getDisciplines() {
      this.loadingDisciplines = true

      this.changeShowData(true)
      //this.clearPpsAndStudents()
      //this.students = await this.GET_STUDENTS_PRACTICE_BY_EDUCATION_PROGRAM(this.educationProgramId)
      await this.GET_VOK_DISCIPLINES(this.educationProgramId)

      this.loadingDisciplines = false
    },
    // clearDisciplines() {
    //
    //   this.checkedDisciplines = []
    //
    //   this.CLEAR_DISCIPLINES()
    // },


  },
  async mounted() {
    //await this.GET_STUDY_LEVELS()
    //await this.GET_STUDY_LANGUAGES()
    await this.GET_MINOR_FORMATION_EDUCATION_PROGRAM(this.urlMinorEducationProgramId)
    await this.GET_EDUCATION_PROGRAM_MINORS(this.urlMinorEducationProgramId)
    await this.GET_EDUCATION_PROGRAMS_FOR_MINOR(this.urlMinorEducationProgramId)


    this.loading = false
  },
}
</script>

<style scoped>

</style>